<template>
    <v-container>
        <v-row>
            <v-col>
                <v-dialog
                v-model="dialog"
                width="400"
                
                transition="dialog-bottom-transition"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon>زیادکرنی مەندوب</v-btn>
                </template>
                <v-card>
                    <v-toolbar
                    dark
                    outlined
                    :class="isUpdate?'orange accent-2':'blue accent-2'"
                    elevation="1"
                    >
                    <v-btn
                        icon
                        
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn  text @click="isUpdate? update():create()" >
                        <v-icon>{{isUpdate? 'mdi-check':'mdi-plus'}}</v-icon> {{isUpdate? 'نوێکردنەوەی مەندوب': 'زیادکردنی مەندوب'}} </v-btn>
                    </v-toolbar-items>
                    </v-toolbar>
                    <v-form ref="user" @submit.prevent>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="12" lg="12" sm="12"><v-text-field @focus="$event.target.select()" label="ناوی مەندوب" dense outlined v-model="user.full_name"  @keydown.enter="create()"></v-text-field></v-col>
                                <v-col cols="12" md="12" lg="12" sm="12"><v-text-field @focus="$event.target.select()" label="ناوی بەکارهێنەر" dense outlined v-model="user.username" :rules="rules.username" @keydown.enter="create()"></v-text-field></v-col>
                                <v-col cols="12" md="12" lg="12" sm="12"><v-text-field @focus="$event.target.select()" label="ووشەی نهێنی" dense outlined v-model="user.password" :rules="rules.password" @keydown.enter="create()"></v-text-field></v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    
                </v-card>
                </v-dialog>
               <v-card class="rounded-lg px-1 py-1">
                   
                  <v-data-table :headers="headers" :items="users" hide-default-footer  :items-per-page="20" >
                      <template v-slot:[`item.actions`]="{ item }">
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon" >
                            <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon" @click="user = {...item}; dialog = true" >mdi-pencil</v-icon
                            >
                            <v-icon large class="ml-2" v-else
                                >mdi-pencil</v-icon
                            >
                            </v-hover>
                            
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon" >
                            <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon" @click="remove(item.user_id)" >mdi-delete</v-icon
                            >
                            <v-icon large class="ml-2" v-else v-bind="item">mdi-delete</v-icon
                            >
                            </v-hover>
                        </template>
                  </v-data-table>
                  <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfUsers/ 20)"  v-model="table.page"></v-pagination>
              </v-card> 
            </v-col>
        </v-row>
        <notification/>
    </v-container>
</template>

<script>
import notification from '../../components/notification.vue'
export default {
  components: { notification },
    data() {
        return {
            users: [],
            table: {
                page: 1,
                numberOfusers: 0,
            },
            dialog: false,
            user:{
                user_id: 0,
                username: '',
                full_name: '',
                password: '',
                role: 'representative'
            },

            headers: [
                {text: 'ناوی مەندوب', value: 'full_name', align: 'center'},
                {text: 'ناوی بەکارهێنەر', value: 'username', align: 'center'},
                {text: 'کردارەکان', value: 'actions', align: 'center'},
            ],
            rules: {
                username: [
                    (v) => {
                        return ( v != '' || 'تکایە ناوی بەکارهێنەر بنووسە')
                    }
                ],
                password: [
                    (v) => {
                        return ( v != '' || 'تکایە ووشەی نهێنی  بنووسە')
                    }
                ],
            }
        }
    },
    computed: {
        isUpdate(){
            return this.user.user_id
        }
    },
    created() {
        this.getUsers()
    },
    methods: {
        async getUsers(){
            try {
                const {data} = await this.axios.get('/user', {params: {page: this.table.page}})
                this.users = data.users
                this.table.numberOfusers = data.count
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
            }
        },
        async create(){
            if (this.$refs.user.validate()) {
                try {
                    await this.axios.post('/user', this.user)
                    this.getUsers()
                    this.reset()
                    this.emitter.$emit('notify', {type: 'success', text:' مەندووبەکە زیادکرا'})
                } catch (error) {
                    
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', {type: 'error', text:`${error.response.data.field} is duplicate`})
                    }
                    this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                }
            }
        },
        async update(){
            if (this.$refs.user.validate()) {
                try {
                    await this.axios.patch('/user/'+ this.user.user_id,this.user)
                    this.getUsers()
                    this.reset()
                    this.emitter.$emit('notify', {type: 'success', text:' زانیاری مەندووبەکە نوێکرایەوە'})
                } catch (error) {
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', {type: 'error', text:`${error.response.data.field} is duplicate`})
                    }
                    this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
                }
            }
        },
        async remove(id){
            this.$confirm('ئایا دڵنیایت لە سڕینەوەی ئەم مەندووبە؟', 'دڵنیابوونەوە', 'question').then(async () => {
            try {
                await this.axios.delete('/user/'+id)
                this.getUsers()
            } catch (error) {
                this.emitter.$emit('notify', {type: 'error', text:'کێشەیەک ڕوویدا!'})
            }
        })
        },
        reset(){
            this.user.user_id = 0
            this.user.username = ''
            this.user.full_name = ''
            this.$refs.user.resetValidation();
        }
    },
    watch: {
        'table.search': function () {
            this.getUsers()
        },
        'table.page': function () {
            this.getUsers()
        }
    }
}
</script>

<style>

</style>